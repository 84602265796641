<template>
  <div class="container">
    <div class="row">
      <app-bar
        title="Isi Saldo"
        previous-link="/wallet"/>
    </div>
    <div class="row">
      <div class="col-12">
        <div>
          <div class="nominal pb-5 pb-md-0 mb-5 mb-md-3">
            <div class="nominal-header mb-3">
              <div class="nominal-title fw-700 fs-14 color-neutral-900">Nominal</div>
            </div>
            <div class="nominal-body d-flex flex-column gap-3">
              <div
                class="nominal-item d-flex align-items-center justify-content-between bg-neutral-100"
                :class="{ active: selectedItem === 1 }">
                <div
                  class="nominal-item-group d-flex align-items-center gap-2"
                  :to="''">
                  <div class="">
                    <div class="nominal-item-group-title fw-700 fs-18 color-neutral-900 mb-1">Rp20.000.000</div>
                    <div class="nominal-item-group-expired fw-400 fs-14 color-neutral-600 mb-0">
                      Mendapatkan <span class="fw-700">1500 Poin</span>
                    </div>
                  </div>
                </div>
                <input
                  class="input-nominal"
                  type="radio"
                  name="input-nominal"
                  @click="selectedItem = 1">
              </div>
              <div
                class="nominal-item d-flex align-items-center justify-content-between bg-neutral-100"
                :class="{ active: selectedItem === 2 }">
                <div
                  class="nominal-item-group d-flex align-items-center gap-2"
                  :to="''">
                  <div class="">
                    <div class="nominal-item-group-title fw-700 fs-18 color-neutral-900 mb-1">Rp10.000.000</div>
                    <div class="nominal-item-group-expired fw-400 fs-14 color-neutral-600 mb-0">
                      Mendapatkan <span class="fw-700">1000 Poin</span>
                    </div>
                  </div>
                </div>
                <input
                  class="input-nominal"
                  type="radio"
                  name="input-nominal"
                  @click="selectedItem = 2">
              </div>
              <div
                class="nominal-item d-flex align-items-center justify-content-between bg-neutral-100"
                :class="{ active: selectedItem === 3 }">
                <div
                  class="nominal-item-group d-flex align-items-center gap-2"
                  :to="''">
                  <div class="">
                    <div class="nominal-item-group-title fw-700 fs-18 color-neutral-900 mb-1">Rp5.000.000</div>
                    <div class="nominal-item-group-expired fw-400 fs-14 color-neutral-600 mb-0">
                      Mendapatkan <span class="fw-700">600 Poin</span>
                    </div>
                  </div>
                </div>
                <input
                  class="input-nominal"
                  type="radio"
                  name="input-nominal"
                  @click="selectedItem = 3">
              </div>
              <div
                class="nominal-item d-flex align-items-center justify-content-between bg-neutral-100"
                :class="{ active: selectedItem === 4 }">
                <div
                  class="nominal-item-group d-flex align-items-center gap-2"
                  :to="''">
                  <div class="">
                    <div class="nominal-item-group-title fw-700 fs-18 color-neutral-900 mb-1">Rp2.500.000.000</div>
                    <div class="nominal-item-group-expired fw-400 fs-14 color-neutral-600 mb-0">
                      Mendapatkan <span class="fw-700">200 Poin</span>
                    </div>
                  </div>
                </div>
                <input
                  class="input-nominal"
                  type="radio"
                  name="input-nominal"
                  @click="selectedItem = 4">
              </div>
               <div
                class="nominal-item d-flex align-items-center justify-content-between bg-neutral-100"
                :class="{ active: selectedItem === 4 }">
                <div
                  class="nominal-item-group d-flex align-items-center gap-2"
                  :to="''">
                  <div class="">
                    <div class="nominal-item-group-title fw-700 fs-18 color-neutral-900 mb-1">Rp2.500.000.000</div>
                    <div class="nominal-item-group-expired fw-400 fs-14 color-neutral-600 mb-0">
                      Mendapatkan <span class="fw-700">200 Poin</span>
                    </div>
                  </div>
                </div>
                <input
                  class="input-nominal"
                  type="radio"
                  name="input-nominal"
                  @click="selectedItem = 4">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="bottom-bar p-3 p-md-0 mb-md-3 text-center">
          <router-link
            :to="{ name: 'Payment' }"
            class="btn btn-orange w-100">
            <span class="fw-600 fs-14 text-white">Lanjut Pembayaran</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import AppBar from '@/components/AppBar'

export default {
  components: {
    AppBar
  },
  setup () {
    const selectedItem = ref(null)

    return {
      selectedItem
    }
  }
}
</script>

<style lang="scss" scoped>
.nominal {
  &-item {
    padding: 12px;
    border-radius: 12px;
    border: 1px solid #F7FAFC;

    .input-nominal {
      width: 24px;
      height: 24px;
    }

    .input-nominal:checked {
      border: 2px solid !important;
      accent-color: #007A98;
    }

    &.active {
      background: #EBFFFD;
      border: 1px solid #007A98 !important;
    }
  }
}

.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: none;
  }
}
</style>
